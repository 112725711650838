import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { Customer } from './Customer';
import { TrailerEquipmentsStore } from './TrailerEquipments';
import { CompartmentStore } from './Trailer/Compartment';
import { TrailerEventStore } from './TrailerEvent';
import { TrailerLicenseStore } from './License';
import { Container } from './Container'


export class Trailer extends Model {
    static backendResourceName = 'trailer';

    static locks = ['manual', 'inlock', 'outlock'];

    @observable id = null;
    @observable licensePlate = '';
    @observable remarks = '';
    @observable fleetvisorId = '';
    @observable _isPickup = true;

    // Properties
    @observable cooler = false;
    @observable fashion = false;
    @observable intermodal = false;
    @observable lightMotionSensor = false;
    @observable gdp = false;
    @observable alarm = false;
    @observable lock = 'manual';
    @observable isChassis = false;

    relations() {
        return {
            customer: Customer,
            equipments: TrailerEquipmentsStore,
            compartments: CompartmentStore,
            trailerLicenses: TrailerLicenseStore,
            trailerevents: TrailerEventStore,
            container1: Container,
            container2: Container,
            nextContainer1: Container,
            nextContainer2: Container,
        };
    }

    parse(data) {
        // Super weird, but _isPickup is FE only. You only get here
        // when data is returned from te server, so we can assume
        // that when the id is not set, it's a dropoff.
        data._isPickup = data.id !== null;

        return super.parse(data);
    }

    updateIsPickUp() {
        this._isPickup = this.id !== null;
    }

    casts() {
        return {
            _isPickup: {
                parse: (attr, value) => {
                    return !!this.id;
                },
                toJS(attr, value) {

                    return value;
                }
            },
        };
    }

    showPickupForm() {
        return !!this.id || this._isPickup;
    }

    // new activity:
    // trailer change > trailer pickup > license plate required
    // load* > trailer form not visible > license plate not required
    // edit activity:
    // trailer change > trailer pickup > license plate required
    // trailer change > trailer drop > license plate not required
    // load* > trailer pick up > license plate not required

    // * or whatever activity type
    licensePlateIsRequired(activity) {
        return activity.type === 'trailer change' && this.showPickupForm();
    }

    validate(activity) {
        if (this.licensePlateIsRequired(activity)) {
            return !!this.licensePlate;
        }
        return true;
    }
}

export class TrailerStore extends Store {
    Model = Trailer;
    static backendResourceName = 'trailer';
}
