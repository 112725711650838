import React from 'react';
import PropTypes from 'prop-types';

const IconContainerChange = props => {
    return (
        <svg viewBox="0 0 55 55" {...props}>
            <g fill="none" fillRule="evenodd">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M43.892 32.5184L45.6265 30.7176C41.6001 26.8298 35.2136 26.4634 30.7492 30.0722C28.0007 32.2939 26.5726 35.5437 26.571 38.8225L23.9443 35.5728L22.0001 37.1444L27.5007 43.9493L34.3055 38.4487L32.7339 36.5044L29.0929 39.4462C28.898 36.6897 30.0087 33.8854 32.3208 32.0165C35.7931 29.2096 40.7603 29.4946 43.892 32.5184ZM53.6427 40.4981L48.142 33.6933L41.3372 39.1939L42.9088 41.1382L46.5497 38.1953C46.745 40.9521 45.6344 43.7569 43.322 45.6261C39.8496 48.433 34.8824 48.148 31.7508 45.1242L30.0162 46.9249C34.0426 50.8127 40.4291 51.1792 44.8936 47.5704C47.6421 45.3486 49.0701 42.0988 49.0718 38.8201L51.6984 42.0697L53.6427 40.4981Z"
                    fill={props.color || "#35A798"}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 1C1.89543 1 1 1.89543 1 3V27C1 28.1046 1.89543 29 3 29H27.9083C30.3549 26.53 33.7488 25 37.5 25C41.2512 25 44.6451 26.53 47.0917 29H52C53.1046 29 54 28.1046 54 27V3C54 1.89543 53.1046 1 52 1H3ZM8 7C8 6.44772 7.55228 6 7 6C6.44772 6 6 6.44772 6 7L6 23C6 23.5523 6.44771 24 7 24C7.55228 24 8 23.5523 8 23L8 7ZM12.8571 6C13.4094 6 13.8571 6.44772 13.8571 7L13.8571 23C13.8571 23.5523 13.4094 24 12.8571 24C12.3049 24 11.8571 23.5523 11.8571 23L11.8571 7C11.8571 6.44772 12.3049 6 12.8571 6ZM19.7143 7C19.7143 6.44772 19.2666 6 18.7143 6C18.162 6 17.7143 6.44772 17.7143 7V23C17.7143 23.5523 18.162 24 18.7143 24C19.2666 24 19.7143 23.5523 19.7143 23V7ZM24.5714 6C25.1237 6 25.5714 6.44772 25.5714 7V23C25.5714 23.5523 25.1237 24 24.5714 24C24.0191 24 23.5714 23.5523 23.5714 23V7C23.5714 6.44772 24.0191 6 24.5714 6ZM31.4286 7C31.4286 6.44772 30.9809 6 30.4286 6C29.8763 6 29.4286 6.44772 29.4286 7V23C29.4286 23.5523 29.8763 24 30.4286 24C30.9809 24 31.4286 23.5523 31.4286 23V7ZM36.2857 6C36.838 6 37.2857 6.44772 37.2857 7V23C37.2857 23.5523 36.838 24 36.2857 24C35.7334 24 35.2857 23.5523 35.2857 23V7C35.2857 6.44772 35.7334 6 36.2857 6ZM43.1429 7C43.1429 6.44772 42.6951 6 42.1429 6C41.5906 6 41.1429 6.44772 41.1429 7V23C41.1429 23.5523 41.5906 24 42.1429 24C42.6951 24 43.1429 23.5523 43.1429 23V7ZM48 6C48.5523 6 49 6.44772 49 7V23C49 23.5523 48.5523 24 48 24C47.4477 24 47 23.5523 47 23V7C47 6.44772 47.4477 6 48 6Z"
                    fill={props.color || "#35A798"}
                />
            </g>
        </svg>
    );
};
IconContainerChange.propTypes = {
    color: PropTypes.string,
};

export default IconContainerChange;
