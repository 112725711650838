import { observable } from 'mobx';
import { Model, Store } from 'store/Base';
import { Customer } from './Customer';


export class Container extends Model {
    static backendResourceName = 'container';

    @observable id = null;
    @observable licensePlate = '';
    @observable remarks = '';
    @observable _isPickup = true;


    relations() {
        return {
            customer: Customer,
        };
    }

    parse(data) {
        // Super weird, but _isPickup is FE only. You only get here
        // when data is returned from te server, so we can assume
        // that when the id is not set, it's a dropoff.
        data._isPickup = data.id !== null;

        return super.parse(data);
    }

    updateIsPickUp() {
        this._isPickup = this.id !== null;
    }

    casts() {
        return {
            _isPickup: {
                parse: (attr, value) => {
                    return !!this.id;
                },
                toJS(attr, value) {

                    return value;
                }
            },
        };
    }

    showPickupForm() {
        return !!this.id || this._isPickup;
    }

    // new activity:
    // trailer change > trailer pickup > license plate required
    // load* > trailer form not visible > license plate not required
    // edit activity:
    // trailer change > trailer pickup > license plate required
    // trailer change > trailer drop > license plate not required
    // load* > trailer pick up > license plate not required

    // * or whatever activity type
    licensePlateIsRequired(activity) {
        return activity.type === 'container change' && this.showPickupForm();
    }

    validate(activity) {
        if (this.licensePlateIsRequired(activity)) {
            return !!this.licensePlate;
        }
        return true;
    }
}

export class ContainerStore extends Store {
    Model = Container;
    static backendResourceName = 'container';
}
